import React, { useState } from 'react';
import { useAuth } from '../config/AuthContext';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { useTranslate } from '../config/translateContext';
import Accordion from 'react-bootstrap/Accordion';
import RegulaminInfoteka from '../components/regulaminInfoteka';
import axiosInstance from "../config/axiosConfig";


const PostCreate = () => {
    const { isAuthenticated, hasObrotny } = useAuth();
    const [title, setTitle] = useState('');
    const [intro, setIntro] = useState('');
    const [img, setImg] = useState(null);
    const [banner, setBanner] = useState(null);
    const [akapity, setAkapity] = useState([{ content: '', img: null }]);
    const [sources, setSources] = useState(''); // Dodane pole sources
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const {isTranslated} = useTranslate();
    const [isVideo, setIsVideo] = useState(false); // Stan dla przełącznika
    const [video, setVideo] = useState(''); // Stan dla linku osadzenia filmu






const handleAkapitChange = (index, e) => {
    const newAkapity = [...akapity];
    newAkapity[index]['content'] = e.target.value;
    setAkapity(newAkapity);
};


    const handleAkapitFileChange = (index, e) => {
        const newAkapity = [...akapity];
        newAkapity[index]['img'] = e.target.files[0];
        setAkapity(newAkapity);
    };

    const addAkapit = () => {
        setAkapity([...akapity, { content: '', img: null }]);
    };

    const removeAkapit = (index) => {
        const newAkapity = [...akapity];
        newAkapity.splice(index, 1);
        setAkapity(newAkapity);
    };

    const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null); // Reset błędu przed próbą wysłania

    const token = localStorage.getItem('access_token');
    if (!token) {
        setError('No access token');
        setIsLoading(false);
        return;
    }

    try {
        const formData = new FormData();

        // Dodajemy podstawowe dane formularza
        formData.append('title', title);
        formData.append('intro', intro);
        formData.append('sources', sources); // Pole sources
        if (img) formData.append('img', img);
        if (banner) formData.append('banner', banner);
        if (video) formData.append('video', video);

        // Serializujemy dane akapitów (bez obrazów)
        const akapityData = akapity.map((akapit, index) => ({
            content: akapit.content,
            img_index: akapit.img ? index : null, // Powiązanie indeksu dla obrazów
        }));
        formData.append('akapity', JSON.stringify(akapityData));

        // Dodajemy obrazy akapitów osobno
        akapity.forEach((akapit, index) => {
            if (akapit.img) {
                formData.append(`akapity_imgs_${index}`, akapit.img);
            }
        });

        // Wysyłamy dane do API
        const response = await axiosInstance.post('post/create/', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 201) {
            navigate(`/post/success`); // Przekierowanie w przypadku sukcesu
        } else {
            setError('Failed to create post. Please try again later.');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        setError(error.response?.data?.message || 'An unexpected error occurred.');
    } finally {
        setIsLoading(false);
    }
};


    const handleFileChange = (e) => {
        setImg(e.target.files[0]);
    };


  const handleSwitchChange = (e) => {
    setIsVideo(e.target.checked); // Ustaw stan przełącznika
    if (!e.target.checked) {
      setVideo(''); // Wyczyszczenie linku w przypadku wyłączenia
    }
  };

  const handleVideoChange = (value) => {
    setVideo(value); // Ustawienie wartości linku
    console.log('Video link:', value); // Debugowanie
  };



    if (!isAuthenticated) {
        return <h1 className='big-text vh10'>Nie jesteś zalogowany</h1>;
    }
    if (isLoading) {
        return <Loader />;
    }
    return (
<Container className="vh10">
<h1>{isTranslated ? 'Erstellen Sie einen Artikel' : 'Stwórz artykuł'}</h1>

    <Form onSubmit={handleSubmit} method='post'>
        <Form.Group controlId="title">
            <Form.Label>{isTranslated ? 'Titel' : 'Tytuł'}</Form.Label>
            <Form.Control 
                type="text" 
                value={title} 
                onChange={(e) => setTitle(e.target.value)} 
                required 
            />
        </Form.Group>
        <Form.Group controlId="intro" className='my-5'>
            <Form.Label>{isTranslated ? 'Einführung' : 'Wstęp'}</Form.Label>
            <p>{isTranslated ? '*Was du hier schreibst, erscheint auf der Startseite unter dem Bild' : '*To co tu napiszesz pojawi się na głównej stronie pod zdjęciem'}</p>
            <Form.Control 
                as="textarea" 
                rows={3} 
                value={intro} 
                onChange={(e) => setIntro(e.target.value)} 
                required 
            />
        </Form.Group>
        <Form.Group controlId="img" className='my-5'>
            <Form.Label>{isTranslated ? 'Bild für den Artikel' : 'Grafika do artykułu'}</Form.Label>
            <Form.Control 
                type="file" 
                onChange={handleFileChange} 
            />
        </Form.Group>
        <Form.Group controlId="banner" className='my-5'>
            <Form.Label>{isTranslated ? 'Ihr Werbebanner' : 'Twój banner reklamowy'}</Form.Label>
            <Form.Control 
                type="file" 
                onChange={(e) => setBanner(e.target.files[0])} // Zaktualizowano handler dla banner
            />
        </Form.Group>
      {/* Przełącznik */}
      <Form.Check
        type="switch"
        id="custom-switch"
        label={isTranslated ? 'Ich veröffentliche ein Video' : 'Publikuję film'}
        checked={isVideo} // Powiązanie stanu z przełącznikiem
        onChange={handleSwitchChange} // Obsługa zmiany stanu
      />

      {/* Komponent widoczny, gdy isVideo === true */}
      {isVideo && (
        <Form.Group controlId="video" className="my-5">
          <Form.Label>
            {isTranslated ? 'Einführung' : 'Link do osadzenia filmu (embed)'}
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={video} // Powiązanie stanu z polem tekstowym
            onChange={(e) => handleVideoChange(e.target.value)} // Obsługa zmiany wartości
            required
          />
        </Form.Group>
      )}








        <p>{isTranslated ? '*Sie können den Text in mehrere Absätze unterteilen und jedem ein Bild hinzufügen, aber Sie können auch formatierten Text aus z.B. Word einfügen. Die Seite wird seine Formatierung beibehalten' :
        '*Możesz rozdzielić treść na kilka akapitów i do każdego dodać grafikę, ale możesz też wkleić sformatowany tekst z np. Word. Strona zachowa jego formatowanie'}</p>

        {akapity.map((akapit, index) => (
  <div key={index} className="mb-5">
        <Form.Group controlId={`akapity[${index}][content]`} className='my-5'>
            <Form.Label>{isTranslated ? `Absatz ${index + 1}` : `Akapit ${index + 1}`}</Form.Label>
            <Form.Control
                as="textarea"
                rows={3}
                // Usuwamy name, ponieważ nie jest potrzebne
                value={akapit.content}
                onChange={(e) => handleAkapitChange(index, e)}
                required
            />
                </Form.Group>
                <Form.Group controlId={`akapity[${index}][img]`} className='my-5'>
                    <Form.Label>{isTranslated ? `Bild für Absatz ${index + 1}` : `Grafika do akapitu ${index + 1}`}</Form.Label>
                    <Form.Control
                        type="file"
                        name="img"
                        onChange={(e) => handleAkapitFileChange(index, e)}
                    />
                </Form.Group><Button variant="secondary" onClick={addAkapit}>
            {isTranslated ? 'Absatz hinzufügen' : 'Dodaj akapit'}
        </Button>
                <Button variant="danger" onClick={() => removeAkapit(index)}>
                    {isTranslated ? 'Absatz entfernen' : 'Usuń akapit'}
                </Button>
            </div>
        ))}
        <Form.Group controlId="sources" className='my-5'>
        <p>*wpisz tu źródła swojej wiedzy, lub wyjaśnienie, a także możesz napisać wiadomość do edytora!</p>
            <Form.Label>{isTranslated ? 'Quellen' : 'Źródła'}</Form.Label>
            <Form.Control 
                as="textarea" 
                rows={3} 
                value={sources} 
                onChange={(e) => setSources(e.target.value)} 
            />
        </Form.Group>
<div className='d-block'>

        <Button variant="primary" type="submit" className='mx-3'>
            {isTranslated ? 'Einreichen' : 'Wyślij'}
        </Button><br></br>
        <small className='text-danger'>
  {isTranslated ? 'Indem Sie den Artikel einreichen, akzeptieren Sie die Regeln der Infothek.' : 'Wysyłając artykuł akceptujesz zasady regulaminu Infoteki'}
</small>

</div>
        {error && <p className="text-danger mt-3">{error}</p>}
    </Form>
    <div className='row mb-5'>
    <Accordion>
      <Accordion.Item eventKey="0">
      <Accordion.Header>
  {isTranslated ? 'Regulamin der Infothek' : 'Regulamin Infoteki'}
</Accordion.Header>

        <Accordion.Body>
<RegulaminInfoteka  />
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
    </div>
</Container>



    );
};

export default PostCreate;
