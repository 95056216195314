import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from '../config/axiosConfig'; // Import configured axios instance
import NewsletterForm from '../components/newsletter';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { fadeAnimation } from '../animations/gsap';
import { gsap } from 'gsap';
import { Search } from 'react-bootstrap-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useTranslate } from '../config/translateContext';
import AuthContext from '../config/AuthContext'; // Import without brackets for default export
import { useAuth } from '../config/AuthContext'; // Import useAuth
import axiosInstance from '../config/axiosConfig';

function InfotekaList() {

    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState({ posts: [], cykle: [], dzialy: [], website: [], newest: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [translatedTitles, setTranslatedTitles] = useState({});
    const [translatedIntros, setTranslatedIntros] = useState({});
    const navigate = useNavigate();
    const myFade = useRef(null);
    const { isTranslated } = useTranslate();
    
    const mediaUrl = process.env.REACT_APP_MEDIA_URL;
    const { token, refreshToken, setToken, logout, hasObrotny } = useContext(AuthContext);
    const { isAuthenticated } = useAuth();
    const menuRef = useRef(null);















    const scroll = (direction) => {
        const { current } = menuRef;
        const scrollAmount = 200;
        if (direction === 'left') {
            current.scrollBy({ left: -scrollAmount, top: 0, behavior: 'smooth' });
        } else {
            current.scrollBy({ left: scrollAmount, top: 0, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (myFade.current) {
            fadeAnimation(myFade.current);
        }
    }, [data]);


    const translateTexts = async (texts, postId) => {
        try {
            setLoading(true);
    
            const response = await axiosInstance.post('/translate/', { texts: texts });


    
            const { translated_title, translated_intro } = response.data;
    
            if (translated_title && postId !== null) {
                setTranslatedTitles(prevTitles => ({
                    ...prevTitles,
                    [postId]: translated_title
                }));
            }
            if (translated_intro && postId !== null) {
                setTranslatedIntros(prevIntros => ({
                    ...prevIntros,
                    [postId]: translated_intro
                }));
            }
        } catch (error) {
            
        } finally{
            setLoading(false);
        }

    };
    
    
    
    useEffect(() => {
        const elementsToAnimate = gsap.utils.toArray('.animatable');
        if (elementsToAnimate.length > 0) {
            gsap.fromTo(elementsToAnimate, { opacity: 0, x: -10 }, { opacity: 1, x: 0, duration: 0.5, stagger: 0.2 });
        }
    }, [isTranslated]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const fetchData = async (searchQuery = '') => {
        try {
            setLoading(true);
            const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
            const response = await axiosInstance.get('/infoteka', {
                params: { search: searchQuery },
                headers: headers
            });
            console.log("API response:", response.data);  // Debugging output
            setData(response.data);
        } catch (error) {
            console.error("Error during data fetching:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchData(); 
        setLoading(false);
    }, []);

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        fetchData(searchQuery);
        setLoading(false);
    };

    const handleTranslateAll = (post) => {
        const texts = { title: post.title, intro: post.intro };
        translateTexts(texts, post.id);
    };
    const handleButton = () => {
        navigate('/post/create');
    }
    return (
        <div className='container vh10'>
   
            <div className='row d-flex mt-5'>
                <div className='col-md-8'>
             <h1>
  {isTranslated ? `Infotheke` : `Infoteka`}
  {hasObrotny ? (
    <span className="d-inline-block ml-3">
      <Button variant="warning" onClick={handleButton}>
        {isTranslated ? "Artikel hinzufügen" : "Dodaj artykuł"}
      </Button>
    </span>
  ) : (
    <span className="d-inline-block ml-3" data-toggle="tooltip" title={isTranslated ?  "Obrotny-Abonnement erforderlich" : "Wymagana subskrybcja Obrotny"}>
      <Button variant="warning" disabled>
        {isTranslated ? "Artikel hinzufügen" : "Dodaj artykuł"}
      </Button>
    </span>
  )}
</h1>


                </div>
            </div>
            <div className='mt-1 neumorphism pt-3'>
                
                <Navbar expand="lg" className="navbar">
                    <Container className='position-relative'>
                        <Row className='w-100 d-flex align-items-center p2-1'>
                            <div className="menu-wrapper col-md-12 col-lg-12">
                                <span className="pointer left-pointer" onClick={() => scroll('left')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffca2c" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                                    </svg>
                                </span>
                                <div className="menu-item" ref={menuRef}>
                                    {data.dzialy.map((dzial, index) => (
                                        <Link className='p-2' key={index} to={`/infoteka/dzialy/${dzial.slug}`}>
                                            <span>{isTranslated ? dzial.name_de : dzial.name}</span>
                                        </Link>
                                    ))}
                                </div>
                                <span className="pointer right-pointer" onClick={() => scroll('right')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffca2c" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                                    </svg>
                                </span>
                            </div>
                            <div className='col-md-12 col-lg-12 col-12 col-sm-12 d-flex justify-content-center w-100  pr-0 mr-0'>
                                <Form inline className='d-flex align-items-center gap-1 w-75 p-2 mt-2' onSubmit={handleSearchSubmit}>
                                    <Col xs="11" lg='11'>
                                        <Form.Control
                                            type="text"
                                            placeholder={isTranslated ? `sucht` : `szukaj`}
                                            className=" w-100"
                                            value={searchQuery}
                                            onChange={handleSearchChange}

                                        />
                                    </Col>
                                    <Col xs="1" lg='1'>
                                        <Button type="submit" variant='warning'><Search size={10}/></Button>
                                    </Col>
                                </Form>
                            </div>
                        </Row>
                    </Container>
                </Navbar>
            </div>
            <div className='row my-5'>
                        <h2>{isTranslated ? 'Wenn Sie Experte auf Ihrem Gebiet sind,' : 'Jeżeli jesteś specjalistą w swojej dziedzinie,'}</h2>
                        <p>{isTranslated ? 'fügen Sie einen Artikel hinzu. Sie können dies mit einem "Obrotny"-Abonnement tun.' : 'dodaj artykuł. Możesz to zrobić posiadając subskrybcję "Obrotny"'}</p>
                        <p>{isTranslated ? 'Wir werden es bewerben und so werden wir Sie fördern!' : 'Będziemy go promować, a w ten sposób Ciebie!'}</p>
                    </div>
            {searchQuery ? (
               <div className='row vh10'>
                    <h2 className='mb-5'>{isTranslated ? "Suchergebnisse" : "Wyniki wyszukiwania"}</h2>
                    {data.newest.map((post, index) => (
                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 my-5' key={post.id}>
                             <Link to={`/infoteka/${post.slug}/`}>
                            <div className='card neumorphism'>
                                <img src={`${mediaUrl}${post.img}`} className="card-img-top" alt={post.title} />
                                <div className='card-category'>
                                    <strong>{isTranslated ? post.presscategory.name_de : post.presscategory.name}</strong>
                                </div>
                                <div className='card-body'>
                                    <h5 className='card-title'>
                                        {translatedTitles[post.id] || post.title} 
                                    </h5>
                      
                                    <Button className='btn btn-link purple' onClick={() => handleTranslateAll(post)}>   <i className="bi bi-translate"></i></Button>
                       
                                </div>
                            </div>            </Link>
                        </div>
                    ))}
                </div>
            ) : (
                <div className='row vh10'>
                    {isTranslated ? data.website.map((item) => (
                        <h2 className='animatable my-5' key={item.id} dangerouslySetInnerHTML={{ __html: item.content_de }} />
                    )) : data.website.map((item) => (
                        <h2 className='animatable my-5' key={item.id} dangerouslySetInnerHTML={{ __html: item.content }} />
                    ))}
                    {data.newest.map((post, index) => (
                        <div className='col-12 col-sm-12 col-md-6 col-lg-4 my-5' key={post.id}>
                            <Link to={`/infoteka/${post.slug}/`}>
                            <div className='card neumorphism'>
                                <img src={`${mediaUrl}${post.img}`} className="card-img-top" alt={post.title} />
                                <div className='card-category'>
                                    <strong>{isTranslated ? post.presscategory.name_de : post.presscategory.name}</strong>
                                </div>
                                <div className='card-body'>
                                    <h5 className='card-title'>
                                        {translatedTitles[post.id] || post.title}
                                    </h5>
                     
                                    <Button className='btn btn-link purple' onClick={() => handleTranslateAll(post)}>   <i className="bi bi-translate"></i></Button>
                                    
                                </div>
                            </div></Link>
                        </div>
                    ))}
                </div>
            )}
            <div className='row my-5'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
                    <NewsletterForm />
                </div>
            </div>
        </div>
    );
}

export default InfotekaList;
