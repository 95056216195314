import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Search } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslate } from '../config/translateContext';
import { useAuth } from '../config/AuthContext';

import axiosInstance from '../config/axiosConfig';

import { Form, FormControl, InputGroup } from 'react-bootstrap';


function WorkList() {
  const [data, setData] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isTranslated } = useTranslate();
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const [searchQuery, setSearchQuery] = useState('');
  const menuRef = useRef(null);
  const [lands, setLands] = useState([]);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedLand, setSelectedLand] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const { isAuthenticated, hasObrotny, hasSzukajacy, token } = useAuth();
  const [myWorks, setMyWorks] = useState([]);
  const [selectedJob, setSelectedJob] = useState([]);
  const [advertisements, setAdvertisments] = useState([]);
  const [navbarJobs, setNavbarJobs] = useState(false);


  console.log('Advertisements:', advertisements);


    const scroll = (direction) => {
        const { current } = menuRef;
        const scrollAmount = 200;
        if (direction === 'left') {
            current.scrollBy({ left: -scrollAmount, top: 0, behavior: 'smooth' });
        } else {
            current.scrollBy({ left: scrollAmount, top: 0, behavior: 'smooth' });
        }
    };


  const fetchLandsAndCitiesAndCategories = useCallback(async () => {
  setLoading(true);
  try {
    const response = await axiosInstance.get('/praca/');


    if (response.data && response.data.results) {
      const results = response.data.results;
      // Ustawienie stanu dla lands, cities, categories na podstawie results
      setLands(Array.isArray(results.land) ? results.land : []);
      setCities(Array.isArray(results.city) ? results.city : []);
      setCategories(Array.isArray(results.category) ? results.category : []);
      setFilteredCategories(Array.isArray(results.category) ? results.category : []);
      setMyWorks(Array.isArray(results.my_works) ? results.my_works : []);
      setAdvertisments(Array.isArray(results.work_advertisements) ? results.work_advertisements : []);
    } else {
      // Jeśli `results` jest undefined, ustawiamy puste tablice, żeby zapobiec błędom
      setLands([]);
      setCities([]);
      setCategories([]);
      setFilteredCategories([]);
      setMyWorks([]);
    }
  } catch (error) {
    console.error('Error fetching lands, cities, and categories:', error);
    setError(error.message);
  } finally {
    setLoading(false);
  }
}, []);


  useEffect(() => {
    fetchLandsAndCitiesAndCategories();
  }, [fetchLandsAndCitiesAndCategories]);


const handleLandChange = useCallback(async (slug) => {
  setSelectedLand(slug);
  setSelectedCity('');
  setSelectedCategory('');
  setLoading(true); // Pokaż loader podczas ładowania danych miast

  try {
    // Wykonaj zapytanie do backendu, wysyłając wybrany `land`
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/praca/`, {
      params: { land: slug },
      withCredentials: true,
    });

    // Ustawienie miast na podstawie odpowiedzi
    if (response.data && response.data.results) {
      setCities(response.data.results.queried_cities || []);
    } else {
      setCities([]);
    }

    console.log('Cities for selected land:', response.data.results.queried_cities);
  } catch (error) {
    console.error('Error fetching cities:', error);
    setError(error.message);
  } finally {
    setLoading(false);
  }
}, []);


const handleCityChange = useCallback(async (cityId) => {
  setSelectedCity(cityId);
  setSelectedCategory('');
  setLoading(true); // Pokaż loader podczas ładowania danych kategorii

  try {
    // Wykonaj zapytanie do backendu, wysyłając wybrane `cityId`
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/praca/`, {
      params: { city: cityId },
      withCredentials: true,
    });

    // Ustawienie kategorii na podstawie odpowiedzi
    if (response.data && response.data.results) {
      setFilteredCategories(response.data.results.queried_category || []);
    } else {
      setFilteredCategories([]);
    }

    console.log('Categories for selected city:', response.data.results.queried_category);
  } catch (error) {
    console.error('Error fetching categories:', error);
    setError(error.message);
  } finally {
    setLoading(false);
  }
}, []);

const handleCategoryChange = useCallback(async (categoryId) => {
  setSelectedCategory(categoryId);
  setLoading(true); // Pokaż loader podczas ładowania danych stanowisk

  try {
    // Wykonaj zapytanie do backendu, wysyłając wybrane `categoryId`
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/praca/`, {
      params: { category: categoryId },
      withCredentials: true,
    });

    // Ustawienie stanowisk na podstawie odpowiedzi
    if (response.data && response.data.results) {
      const queriedJobs = response.data.results.queried_jobs || [];
      setJobs(queriedJobs);

      // Ustaw `navbarJobs` na true, jeśli mamy wyniki dla stanowisk
      if (queriedJobs.length > 0) {
        setNavbarJobs(true);
      } else {
        setNavbarJobs(false); // Ukryj, jeśli nie ma wyników
      }
    } else {
      setJobs([]);
      setNavbarJobs(false);
    }

    console.log('Jobs for selected category:', response.data.results.queried_jobs);
  } catch (error) {
    console.error('Error fetching jobs:', error);
    setError(error.message);
  } finally {
    setLoading(false);
  }
}, []);


const handleJobClick = useCallback(async (jobId) => {
  setSelectedJob(jobId);
  setLoading(true); // Pokaż loader podczas ładowania danych ogłoszeń

  try {
    // Wykonaj zapytanie do backendu, wysyłając wybrane `jobId`
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/praca/`, {
      params: { job: jobId },
      withCredentials: true,
    });

    // Ustawienie ogłoszeń na podstawie odpowiedzi
    if (response.data && response.data.results) {
      setData(response.data.results.queried_adv || []);
      console.log(response.data.results.queried_adv);
    } else {
      setData([]);
    }

    console.log('Advertisements for selected job:', response.data.results.queried_adv);
  } catch (error) {
    console.error('Error fetching advertisements:', error);
    setError(error.message);
  } finally {
    setLoading(false);
  }
}, []);

  const [showHoverMessage, setShowHoverMessage] = useState(false);

  const handleToggleMessage = () => {
    setShowHoverMessage((prev) => !prev);
  };



    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        fetchData(searchQuery);
        setLoading(false);
        console.log('search started');
    };


const handleSearchChange = (e) => {
  setSearchQuery(e.target.value || ''); // Ustaw pusty string, jeśli wartość jest `undefined`
};


const fetchData = async (searchQuery = '') => {
  try {
    setLoading(true);
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
    const response = await axiosInstance.get('/praca', {
      params: { search_query: searchQuery },
      headers: headers,
    });


    setAdvertisments(response.data.results.work_advertisements || []);
  } catch (error) {
    console.error('Error during data fetching:', error);
    setError(error.message);
  } finally {
    setLoading(false);
  }
};








  if (loading) {
    return     <div className='w-100 d-flex justify-content-center h-100 ontop position-fixed t-0 cloude'>
      <img src='/img/logo.png' className='rotating-object align-self-center loader' alt="Loader" />
    </div>; // Display a loading state until data is fetched
  }

  if (error) {
    return <div>Error: {error}</div>; // Display an error if fetching fails
  }

  return (
    <div className='container work-container-main'>
      <div className='row'>
        <h1 className='my-5'>{isTranslated ? 'Arbeitsmarkt' : 'Praca'}</h1>
        <div className='row mt-2'>
          <div className='d-inline-flex gap-2 d-flex-small'>
            {isAuthenticated && hasObrotny ? (
              <Button as={Link} to="/create-ad" className='orange-button'>
                {isTranslated ? 'Anzeige erstellen' : 'Utwórz ogłoszenie'}
              </Button>
            ) : (
<>
<div className='d-block'>
  <div className="button-container">
    <Button disabled className='dis-work'>
      {isTranslated ? 'Anzeige erstellen' : 'Utwórz ogłoszenie'}
    </Button>
    <p className='hover-message hover-message-left'>
      {isTranslated
        ? 'Um eine Anzeige zu erstellen, müssen Sie das "Obrotny" Abonnement haben.'
        : 'Aby utworzyć ogłoszenie, musisz posiadać subskrypcję "Obrotny"'}
    </p>
  </div>
</div>


</>
            )}

            {isAuthenticated && (hasObrotny || hasSzukajacy) ? (
              <Button as={Link} to="/create-cv" className="orange-button">
                {isTranslated ? 'Lebenslauf erstellen' : 'Utwórz Lebenslauf'}

              </Button>
            ) : (
    <div className='d-block'>
  <div className="button-container">
    <Button className="bg-orange dis-work" disabled>
      {isTranslated ? 'Lebenslauf erstellen' : 'Utwórz Lebenslauf'}
    </Button>
    <p className='hover-message'>
      {isTranslated
        ? 'Um einen Lebenslauf zu erstellen, müssen Sie das "Szukający" Abonnement haben.'
        : 'Aby wygenerować Lebenslauf, musisz posiadać subskrypcję "Szukający"'}
    </p>
  </div>
</div>

            )}

          </div>
                      {myWorks.length > 0 && (
              <DropdownButton className='my-5' id="dropdown-basic-button" title={isTranslated ? "Meine Anzeigen" : "Moje ogłoszenia"}>
                {myWorks.map((work) => (
                  <Dropdown.Item key={work.id} href={`/praca/${work.id}`}>
                    {work.name_pl || work.name_de}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            )}
        </div>
      </div>

      <nav className="navbar navbar-expand-lg vh10 navbar-extra">
        <div className="container">
          <div className="row w-100 align-items-center">
            <div className="col-md-6 d-flex justify-content-around">
              {/* Lands Dropdown */}
              {lands.length > 0 ? (
                <DropdownButton id="dropdown-basic-button" className='drop-small' title={isTranslated ? "Wählen Sie Land" : "Wybierz Land"}>
                  {lands.map((land) => (
                    <Dropdown.Item key={land.id} onClick={() => handleLandChange(land.slug)}>
                      {isTranslated ? land.name_de : land.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              ) : (
                <Dropdown.Item disabled>
                  {isTranslated ? "Keine Länder verfügbar" : "Brak dostępnych landów"}
                </Dropdown.Item>
              )}

              {/* Cities Dropdown */}
              {selectedLand && (
                <DropdownButton id="dropdown-basic-button" className='drop-small' title={isTranslated ? "Wählen Sie Stadt" : "Wybierz Miasto"}>
                  {cities.length > 0 ? (
                    cities.map((city) => (
                      <Dropdown.Item key={city.id} onClick={() => handleCityChange(city.id)}>
                        {city.name}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item disabled>
                      {isTranslated ? "Keine Städte verfügbar" : "Brak dostępnych miast"}
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              )}

              {/* Categories Dropdown */}
              {selectedCity && (
                <DropdownButton className='drop-small' id="dropdown-basic-button" title={isTranslated ? "Wählen Sie Kategorie" : "Wybierz Kategorię"}>
                  {filteredCategories.length > 0 ? (
                    filteredCategories.map((category) => (
                      <Dropdown.Item key={category.id} onClick={() => handleCategoryChange(category.id)}>
                        {category.name}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item disabled>
                      {isTranslated ? "Keine Kategorien verfügbar" : "Brak dostępnych kategorii"}
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              )}
            </div>

            <div className="col-md-6 form-container clearfix">
   <Form className="d-flex align-items-center" onSubmit={(e) => e.preventDefault()}>
  <InputGroup>
    <FormControl
  type="text"
  placeholder={isTranslated ? 'sucht' : 'szukaj'}
  className="form-control me-2 neumorphic-input"
  value={searchQuery || ''} // Ustaw pusty string jako wartość domyślną
  onChange={handleSearchChange}
/>

    <Button
      variant="primary"
      id="btnGroupAddon2"
      onClick={handleSearchSubmit} // Wywołanie funkcji wyszukiwania
    >
      <Search size={20} />
    </Button>
  </InputGroup>
</Form>

            </div>
          </div>
        </div>
      </nav>
      <div className={`navbar navbar-jobs ${navbarJobs ? '' : 'd-none'}`}>
                    <div className='neumorphism position-relative'>
                        <div className='row w-100 d-flex align-items-center p2-1'>
                            <div className="menu-wrapper col-md-12 col-lg-12">
                                <span className="pointer left-pointer" onClick={() => scroll('left')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffca2c" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                                    </svg>
                                </span>
                                <div className="menu-item" ref={menuRef}>
{jobs.map((job, index) => (
  <Link
    className='p-2'
    key={index} // Przekierowanie do strony z ogłoszeniami
    onClick={() => handleJobClick(job.id)}  // Wywołaj funkcję, która pobierze ogłoszenia
  >
    <span>{isTranslated ? job.name_de : job.name}</span>
  </Link>
))}


                                </div>
                                <span className="pointer right-pointer" onClick={() => scroll('right')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffca2c" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                                    </svg>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
 <div className='row vh10 work-row'>
  {Array.isArray(advertisements) && advertisements.length > 0 ? (
    advertisements.map((item, index) => (
      <div className='col-12 col-sm-12 col-md-6 col-lg-4 my-5' key={index}>
        <div className='card neumorphism'>
          {item.img ? (
            <img
              src={`${process.env.REACT_APP_MEDIA_URL}${item.img}`}
              className='card-img-top'
              alt={item.name}
            />
          ) : (
            <div className='placeholder-img'>Brak obrazka</div>
          )}
          <div className='card-category-work'>
            <strong>{item.city?.name}</strong>
          </div>
          <div className='card-body'>
            <h5 className='card-title'>{item.name}</h5>
            <Link to={`/praca/${item.id}/`}>
              {isTranslated ? 'Lesen' : 'Przeczytaj'}
            </Link>
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className='col-12 text-center'>
      {isTranslated ? 'Keine Anzeigen gefunden' : 'Nie znaleziono ogłoszeń'}
    </div>
  )}
</div>



    </div>
  );
}

export default WorkList;
